import React, {useEffect, useState} from 'react'
import { Router, navigate } from '@reach/router'
import { Provider } from "react-redux"
import ReduxStore from "../components/app/store"
import UserCalendar from "../components/app/features/request/user_calendar"
import hasParams from "../components/has_params"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { ElementOrLoader } from "../components/util"
import axios from "axios"
import { API_ADDRESS } from "../lib/constants"

const X = (props) => {

  const [title, setTitle] = useState('Loading Calendar');
  useEffect(() => {
    console.log(props)
    axios.get(`${API_ADDRESS}/user/info-from-handle?handle=${props['*']}`)
      .then((response) => {
          if (response.data.error) {
            throw '';
          }
          setTitle(`Schedule time with ${response.data.name}`)
        }
      );
  }, []);

  return (
    <Provider store={ReduxStore}>
      <SEO title="Loading Calendar"/>
      <div>
        <Router>
          <ElementOrLoader path="/x/" loading={true} />
          <UserCalendar
            {...props}
            path="/x/:userHandle"/>
        </Router>
      </div>
    </Provider>
  );
}

export default hasParams(X);
